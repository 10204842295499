import { Avatar, Space } from "antd";
import { get_api } from "api";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { startCase } from "lodash";

const useLocumUsersList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [locumUsersList, setLocumUsersList] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // For name/email search
  const [pagination, setPagination] = useState({
    current: 1,  // initial page
    pageSize: 10, // initial page size
    total: 0,     // total records
  });

  const getAllLocumUsers = async (
    page = pagination.current,
    pageSize = pagination.pageSize,
    search = ''
  ) => {
    try {
      setIsLoading(true);
      const { data } = await get_api(
        `locumUser?page=${page}&limit=${pageSize}&search=${encodeURIComponent(search)}`
      );

      setLocumUsersList(data.locumUsers || []);
      setPagination((prev) => ({
        ...prev,
        current: page,
        pageSize,
        total: data.totalCount ?? 0,
      }));
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle table pagination changes
  const handleTableChange = (newPagination) => {
    getAllLocumUsers(newPagination.current, newPagination.pageSize, searchTerm);
  };

  // Handle search
  const handleSearch = () => {
    // Reset to first page when searching
    getAllLocumUsers(1, pagination.pageSize, searchTerm);
  };


  useEffect(() => {
    setIsLoading(true);
    getAllLocumUsers();
  }, []);

  const columns = [
    {
      title: "Locum Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <div key={record._id} className="tbl-user-details">
          <Avatar icon={<UserOutlined />} src={record?.profile_photo} />
          <h6 className="m-0 text-primary text-capitalize">{`${
            record?.user?.first_name ?? ""
          } ${record?.user?.last_name ?? ""}`}</h6>
        </div>
      ),
    },
    {
      title: "Locum Contact",
      dataIndex: "contact",
      key: "contact",
      render: (text, record) => (
        <div key={record._id}>
          <div className="fields-wrapper">
            {record?.user?.email && (
              <p className="m-0">{record?.user?.email}</p>
            )}
            {record?.user?.phone && (
              <p className="m-0">{record?.user?.phone}</p>
            )}
          </div>
        </div>
      ),
      align: "center",
    },
    {
      title: "Ahpra Status",
      dataIndex: "ahpraVerified",
      key: "ahpraVerified",
      render: (text, record) => <div key={record._id}>{text}</div>,
      align: "center",
    },
    {
      title: "Registered Type",
      dataIndex: "regfrom",
      key: "regfrom",
      render: (text, record) => (
        <div key={record._id}>{startCase(record?.user?.type)}</div>
      ),
      align: "center",
    },
    {
      title: "LoggedIn Device Info",
      dataIndex: "deviceInfo",
      key: "deviceInfo",
      render: (text, record) => (
        <div key={record._id}>
          {record?.user?.deviceDetails?.deviceModel && (
            <div>
              Device: <span>{record?.user?.deviceDetails?.deviceModel}</span>
            </div>
          )}
          {record?.user?.deviceDetails?.deviceType && (
            <div>
              OS: <span>{record?.user?.deviceDetails?.deviceType}</span>
            </div>
          )}
          {record?.user?.deviceDetails?.versionOS && (
            <div>
              OS Version:{" "}
              <span>{record?.user?.deviceDetails?.versionOS}</span>
            </div>
          )}
          {record?.user?.deviceDetails?.currentAppVersion && (
            <div>
              App Version:{" "}
              <span>{record?.user?.deviceDetails?.currentAppVersion}</span>
            </div>
          )}
          {record?.user?.deviceDetails?.lastLoggedIn && (
            <div>
              Last Login:{" "}
              <span>
                {dayjs(record?.user?.deviceDetails?.lastLoggedIn).format(
                  "DD-MM-YYYY hh:mm A"
                )}
              </span>
            </div>
          )}
        </div>
      ),
      align: "center",
    },
    {
      title: "Registered On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div key={record._id}>
          {dayjs(record?.user?.createdAt).format("DD-MM-YYYY")}
        </div>
      ),
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size={[8, 0]} key={record._id}>
          <i
            className="fas fa-eye"
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/locum-users/${record?.user?._id}/shifts`)
            }
          />
        </Space>
      ),
    },
  ];

  return {
    columns,
    locumUsersList,
    isLoading,
    handleTableChange,
    pagination,
    searchTerm,
    setSearchTerm,
    handleSearch
  };
};

export default useLocumUsersList;
