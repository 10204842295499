import { Button, Input, Spin, Table } from "antd";
import React from "react";
import useLocumUsersListHook from "./useLocumUsersListHook";

const LocumUsersList = () => {
  const { columns, locumUsersList, pagination,handleTableChange, isLoading, searchTerm, setSearchTerm, handleSearch } = useLocumUsersListHook();
  return (
    <div className="content mb-0">
      <h2 className="page-main-title">
        Locums Users
      </h2>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div>
      <div style={{ marginBottom: '1rem' }}>
        <Input
          placeholder="Search name or email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 200, marginRight: 8 }}
        />
        <Button type="primary" onClick={handleSearch}>
          Search
        </Button>
      </div>

      <Spin spinning={isLoading}>
        <Table
          columns={columns}
          dataSource={locumUsersList}
          rowKey="id"
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
          }}
          onChange={handleTableChange}
          scroll={locumUsersList.length > 0 ? { y: 670, x: 1200 } : undefined}
        />
      </Spin>
    </div>
      )}
    </div>
  );
};

export default LocumUsersList;
