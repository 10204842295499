/*eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { get_api } from "api";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { startCase } from "lodash";
import { unPaidHoursList } from "components/GeneralComponents/Utils";
import { toHoursAndMinutes } from "components/GeneralComponents/Utils";

const useLocumShiftsListHook = () => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [locumShifts, setLocumShifts] = useState([]);
  const [locumProfile, setLocumProfile] = useState([]);
  const [activeTab, setActiveTab] = useState("accepted");

  const getShiftsByLocumIdAndStatus = useCallback(async (status) => {
    setIsLoading(true);
    try {
      const { data } = await get_api(`locumUser/${params?.id}/${status}`);
      setLocumShifts(data.locumUsers);
      setLocumProfile(data.locumUsersProfile)
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      render: (text, record) => (
        <div key={record._id} className="tbl-user-details">
          <Avatar
            icon={<UserOutlined />}
            src={`${process.env.REACT_APP_BACKEND_BASE_URI}/${record?.id?.company_profile_id?.companyLogo}`}
          />
          <h6 className="m-0 text-primary text-capitalize">
            {record?.id?.company_profile_id?.companyName}
          </h6>
        </div>
      ),
    },
    {
      title: "Company Contact",
      dataIndex: "contact",
      key: "contact",
      render: (text, record) => (
        <div key={record._id}>
          <div className="fields-wrapper">
            {record?.id?.company_profile_id?.contact?.email && (
              <p className="m-0">{record?.id?.company_profile_id?.contact.email}</p>
            )}
            {record?.id?.company_profile_id?.contact?.phone && (
              <p className="m-0">{record?.id?.company_profile_id?.contact.phone}</p>
            )}
            {record?.id?.company_profile_id?.contact?.website && (
              <span
                className="m-0"
                onClick={() =>
                  window.open(
                    record?.id?.company_profile_id?.contact?.website.includes(
                      "http"
                    )
                      ? record?.id?.company_profile_id?.contact?.website
                      : `https://${record?.id?.company_profile_id?.contact?.website}`,
                    "_blank"
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {record?.id?.company_profile_id?.contact?.website}
              </span>
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <div key={record._id}>
          <p>{dayjs(text).locale("en").format("ddd DD MMM YYYY")}</p>
          <p>
            {record?.id?.start_time} - {record?.id?.end_time}
          </p>
        </div>
      ),
      width: 200,
    },
    {
      title: "Total Hrs",
      dataIndex: "total_hours",
      key: "total_hours",
      render: (text, record) => <div key={record?.id?._id}>{record?.id?.hourly_rate}</div>,
      align: "center",
      width: 120,
    },
    {
      title: "Unpaid",
      dataIndex: "unpaid_hours",
      key: "unpaid_hours",
      render: (text, record) => {
        const unPaidHour = unPaidHoursList.find((uph) => uph?.value === record?.id?.unpaid_hours);
        const uphours = toHoursAndMinutes(unPaidHour?.value.split("-")[0]);
        return <div key={record?.id?._id}>{uphours}</div>;
      },
      align: "center",
      width: 120,
    },
    {
      title: "Rate",
      dataIndex: "total_rate",
      key: "total_rate",
      render: (text, record) => {
        return <div key={record?.id?._id}>$ {record?.id?.total_rate}</div>;
      },
      align: "center",
      width: 120,
    },
    {
      title: "Type",
      dataIndex: "hourly_rate_type",
      key: "hourly_rate_type",
      render: (text, record) => {
        return <div key={record?.id?._id}>{startCase(record?.id?.hourly_rate_type)}</div>;
      },
      align: "center",
      width: 120,
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle" key={record._id}>
    //       <i
    //         className="fas fa-eye"
    //         style={{ cursor: "pointer" }}
    //         onClick={() => navigate(`/locum-users/id/shifts`)}
    //       />
    //     </Space>
    //   ),
    // },
  ];
  if (activeTab === "cancelled") {
    columns.push({
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (text, record) => <div key={record?.id?._id}>{record?.reason}</div>,
      align: "center",
    });
  }
  return {
    columns,
    locumShifts,
    locumProfile,
    isLoading,
    getShiftsByLocumIdAndStatus,
    activeTab,
    setActiveTab,
  };
};

export default useLocumShiftsListHook;
